import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function EtroTeaMasonry(props) {
  console.log(props)
  return (
    <div style={{ columns: props.columnCount, columnGap: 0 }}>
      {props.etroTeaUrl.map((img, i) => (
        <LazyLoadImage
          effect="blur"
          loading="lazy"
          src={img.url}
          alt={img.alt}
          key={i}
          className="image"
          style={{ padding: props.gap / 2 }}
        />
      ))}
    </div>
  )
}
