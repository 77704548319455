export default function Me() {
  return (
    <div class="paragraph">
      <h1 class="paragraph-header">About me</h1>
      <p>
        Hassen Arante is an interior photographer based in Bahrain.
        <br></br>
        Hassen specializes in shooting residential, commercial, and retail spaces. His clients are primarily interior design firms and interior fit-out companies.
      </p>
    </div>
  )
}
