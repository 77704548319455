import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Bcc23Masonry(props) {
  console.log(props)
  return (
    <div style={{ columns: props.columnCount, columnGap: 0 }}>
      {props.bcc23Url.map((img, i) => (
        <LazyLoadImage
          effect="blur"
          loading="lazy"
          src={img}
          key={i}
          className="image"
          style={{ padding: props.gap / 2 }}
        />
      ))}
    </div>
  )
}
