import { busaiteenUrl, marassiBoulevardUrl } from './ImageUrl.js'
import BusaiteenMasonry from './Masonry/BusaiteenMasonry.js'

export default function MarassiBoulevard() {
  return (
    <div>
      <BusaiteenMasonry busaiteenUrl={busaiteenUrl} columnCount="1" gap="5"></BusaiteenMasonry>
    </div>
  )
}
