import { uobPortfolioUrl } from './ImageUrl.js'
import UobPortfolioMasonry from './Masonry/UobPortfolioMasonry.js'

export default function UobPortfolio() {
  return (
    <div>
      <UobPortfolioMasonry uobPortfolioUrl={uobPortfolioUrl} columnCount="1" gap="5"></UobPortfolioMasonry>
    </div>
  )
}
