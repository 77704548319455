import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Adjust according to your app root element

export default function AlHiddApartmentMasonry(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % props.alHiddApartmentUrl.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? props.alHiddApartmentUrl.length - 1 : prevIndex - 1
    );
  };

  return (
    <div>
      <div style={{ columns: props.columnCount, columnGap: 0 }}>
        {props.alHiddApartmentUrl.map((img, i) => (
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            src={img.url}
            alt={img.alt}
            key={i}
            className="image"
            style={{ padding: props.gap / 2, cursor: 'pointer' }}
            onClick={() => openModal(i)}
          />
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(4px)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'none',
            border: 'none',
            padding: 0,
            overflow: 'hidden',
          },
        }}
      >
        <LazyLoadImage
          effect="blur"
          src={props.alHiddApartmentUrl[currentIndex].url}
          alt={props.alHiddApartmentUrl[currentIndex].alt}
          style={{
            maxWidth: '80vw',
            maxHeight: '80vh',
            width: 'auto',
            height: 'auto',
          }}
        />
      </Modal>

      {modalIsOpen && currentIndex > 0 && (
        <button
          onClick={goToPrevious}
          style={{
            position: 'fixed',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            zIndex: 1000,
            height: '100%',
            width: '5%',
            minWidth: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          &#10094; {/* Left arrow icon */}
        </button>
      )}

      {modalIsOpen && currentIndex < props.alHiddApartmentUrl.length - 1 && (
        <button
          onClick={goToNext}
          style={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'linear-gradient(to left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            zIndex: 1000,
            height: '100%',
            width: '5%',
            minWidth: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          &#10095; {/* Right arrow icon */}
        </button>
      )}
    </div>
  );
}
