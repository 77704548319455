import { onyxApartmentUrl } from './ImageUrl.js'
import OnyxApartmentMasonry from './Masonry/OnyxApartmentMasonry.js'

export default function OnyxApartment() {
  return (
    <div>
      <OnyxApartmentMasonry onyxApartmentUrl={onyxApartmentUrl} columnCount="1" gap="5"></OnyxApartmentMasonry>
    </div>
  )
}
