const homeUrl = [
  { url: 'https://i.imgur.com/Vne9jIo.jpeg', alt: 'Architecture Photographer in Bahrain, showing the Bahrain Institute of Banking & Finance (BIBF) building.' },
  { url: 'https://i.imgur.com/nyhHSFs.jpeg', alt: 'Architecture Photographer in Bahrain, showing MS Centre by MSCEB.' },
  { url: 'https://i.imgur.com/uu0XjQq.jpeg', alt: '' },
  { url: 'https://i.imgur.com/9OBXg2y.jpeg', alt: '' },
  { url: 'https://i.imgur.com/4OGAhXL.jpeg', alt: '' },
  { url: 'https://i.imgur.com/9vXNF7M.jpeg', alt: '' },
  { url: 'https://i.imgur.com/nozlqry.jpeg', alt: '' },
  { url: 'https://i.imgur.com/LCIrECK.jpeg', alt: '' },
  { url: 'https://i.imgur.com/mjFEWDq.jpeg', alt: '' },
  { url: 'https://i.imgur.com/ClpfEpV.jpeg', alt: '' },
  { url: 'https://i.imgur.com/8m7WvpL.jpeg', alt: '' },
  { url: 'https://i.imgur.com/7bzelNw.jpeg', alt: '' },
];


const uobPortfolioUrl = [
  'https://i.imgur.com/J67tM6Q.jpeg',
  'https://i.imgur.com/wL0dyOe.jpeg',
  'https://i.imgur.com/4S3N8Yw.jpeg',
  'https://i.imgur.com/iE9GdNU.jpeg',
  'https://i.imgur.com/7z51PnO.jpeg',
  'https://i.imgur.com/SsP1HeF.jpeg',
  'https://i.imgur.com/Wh4wxxT.jpeg',
  'https://i.imgur.com/1bIZsZh.jpeg',
  'https://i.imgur.com/W0ITRA9.jpeg',
  'https://i.imgur.com/Z64GiOf.jpeg',
  'https://i.imgur.com/zVF0hgP.jpeg',
  'https://i.imgur.com/8xyGUoY.jpeg',
  'https://i.imgur.com/hp9g0lq.jpeg',
  'https://i.imgur.com/6u7ZfYr.jpeg',
  'https://i.imgur.com/82HXS2l.jpeg',
  'https://i.imgur.com/8UkfEYU.jpeg',
]



const realEstateUrl = [
  { url: 'https://i.imgur.com/U5ovLcm.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/WUy3fmz.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/tOk6pac.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/tv0bd5x.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/IYVnwYY.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/eVbaEIV.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/05aZAMz.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/2tLgj6S.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/ONfAn2G.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/si1OJcG.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/IG3MGA3.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/faH3zhe.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/WD8w0cr.jpeg', alt: 'insert' }
];



const retailUrl = [
  'https://i.imgur.com/tTyeGus.jpeg',
  'https://i.imgur.com/Vs1AFC4.jpeg',
  'https://i.imgur.com/hrxZynZ.jpeg',
  'https://i.imgur.com/ekzQjmW.jpeg',
  'https://i.imgur.com/rG3Nvaa.jpeg',
  'https://i.imgur.com/gXi7IZz.jpeg',
  'https://i.imgur.com/zITvaPP.jpeg',
  'https://i.imgur.com/anqRQyJ.jpeg',
  'https://i.imgur.com/nSLfDtz.jpeg',
]

const bccUrl = [
  'https://i.imgur.com/DUp9dA9.jpeg',
  'https://i.imgur.com/CZCPjq2.jpeg',
  'https://i.imgur.com/sVIhb3P.jpeg',
  'https://i.imgur.com/jqMCb94.jpeg',
  'https://i.imgur.com/bzBAfuN.jpeg',
  'https://i.imgur.com/YneakuV.jpeg',
  'https://i.imgur.com/rZvQaav.jpeg',
  'https://i.imgur.com/y6O1eyk.jpeg',
  'https://i.imgur.com/YzxZcPB.jpeg',
  'https://i.imgur.com/gVuAVNB.jpeg',
  'https://i.imgur.com/wCQUJAK.jpeg',

  'https://i.imgur.com/2UX5bdY.jpeg',
  'https://i.imgur.com/GdXTQrN.jpeg',
  'https://i.imgur.com/giECgAG.jpeg',

  'https://i.imgur.com/F6c20hj.jpeg',
  'https://i.imgur.com/QcOYa1o.jpeg',
  'https://i.imgur.com/N38PPTQ.jpeg',

  'https://i.imgur.com/6d9jcaq.jpeg',
  'https://i.imgur.com/4XHAsjy.jpeg',
  'https://i.imgur.com/gs2OmtL.jpeg',

  'https://i.imgur.com/UJLAXDQ.jpeg',
  'https://i.imgur.com/gFtTTnq.jpeg',
  'https://i.imgur.com/FM16AUH.jpeg',

  'https://i.imgur.com/z9QiPtG.jpeg',
  'https://i.imgur.com/atRQEyb.jpeg',
  'https://i.imgur.com/5KibQwO.jpeg',

  'https://i.imgur.com/Yx6Ga0Y.jpeg',
  'https://i.imgur.com/rDrywbQ.jpeg',

  'https://i.imgur.com/qeoJpOi.jpeg',
  'https://i.imgur.com/xYUIhCo.jpeg',
  'https://i.imgur.com/SvQdTik.jpeg',

  'https://i.imgur.com/hrOzsLd.jpeg',
  'https://i.imgur.com/OrJq7OM.jpeg',
  'https://i.imgur.com/xmVWGtb.jpeg',
]

const marassiBoulevardUrl = [
  { url: 'https://i.imgur.com/jpDCfrI.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Marassi Boulevard showing the design in the masters bedroom.' },
  { url: 'https://i.imgur.com/9OBXg2y.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Marassi Boulevard showing the design in the masters bedroom.' },
  { url: 'https://i.imgur.com/JCtqpJs.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Marassi Boulevard showing a kids room.' },
  { url: 'https://i.imgur.com/YGV5Jyn.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Marassi Boulevard showcasing a custom made cabinet in kids room.' },
  { url: 'https://i.imgur.com/bVUXZ0e.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Marassi Boulevard showing the living room and the view.' },
  { url: 'https://i.imgur.com/NRwAcDc.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Marassi Boulevard showing the round dining table.' }
];

const busaiteenUrl = [
  { url: 'https://i.imgur.com/VIC7RDM.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Busaiteen Villa showing the design in the boys bedroom.' },
  { url: 'https://i.imgur.com/8m7WvpL.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Busaiteen Villa showing the boys room.' },
  { url: 'https://i.imgur.com/cETbOPE.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Busaiteen Villa showing the girls room.' },
  { url: 'https://i.imgur.com/ZRywJL4.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Busaiteen Villa showing details of the wall design.' },
];


const alHiddApartmentUrl = [
  { url: 'https://i.imgur.com/mjFEWDq.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Al Hidd Villa showing the interior design in the bedroom.' },
  { url: 'https://i.imgur.com/5aod7do.jpeg', alt: 'Fitout Photographer in Bahrain showing a room in Al Hidd, Bahrain' },
  { url: 'https://i.imgur.com/D5r7Y6t.jpeg', alt: 'Fitout Photographer in Bahrain showing a room in Al Hidd, Bahrain' },
  { url: 'https://i.imgur.com/fDX5mrg.jpeg', alt: 'Fitout Photographer in Bahrain showing a room in Al Hidd, Bahrain' },
  { url: 'https://i.imgur.com/ClpfEpV.jpeg', alt: 'Fitout Photographer in Bahrain showing a room in Al Hidd, Bahrain' },
  { url: 'https://i.imgur.com/TC5JiQh.jpeg', alt: 'Fitout Photographer in Bahrain showing a room in Al Hidd, Bahrain' },
  { url: 'https://i.imgur.com/jgdwUF6.jpeg', alt: 'Fitout Photographer in Bahrain showing a room in Al Hidd, Bahrain' },
];

const onyxApartmentUrl = [
  { url: 'https://i.imgur.com/nozlqry.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Onyx Residences showing Bahrain World Trade Cente in the view.' },
  { url: 'https://i.imgur.com/9vXNF7M.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Onyx Residences showing the interior design of the bedroom.' },
  { url: 'https://i.imgur.com/n8Qwp26.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Onyx Residences showing the view from the balcony.' },
  { url: 'https://i.imgur.com/cSBffR6.jpeg', alt: 'Interior Photographer in Bahrain for an apartment in Onyx Residences showing the interior design of the toilet.' },
];

const sanadVillaUrl = [
  { url: 'https://i.imgur.com/uakf2dP.jpeg', alt: 'Interior Photography in Bahrain for a Villa in Sanad showing the overall room design.' },
  { url: 'https://i.imgur.com/BxdkBSv.jpeg', alt: 'Interior Photographer in Bahrain for a Villa in Sanad showing the design for the desk.' },
  { url: 'https://i.imgur.com/xDRRdc1.jpeg', alt: 'Interior Photography in Bahrain for a Villa in Sanad showing the design for the wall.' },
  { url: 'https://i.imgur.com/gGsz2H5.jpeg', alt: 'Interior Photography in Bahrain for a Villa in Sanad showing cabinet storage' },
];

const platinumlistOfficeUrl = [
  { url: 'https://i.imgur.com/uu0XjQq.jpeg', alt: 'Interior Photography in Bahrain of Platinumlist Office in Seef showing the meeting room.' },
  { url: 'https://i.imgur.com/XNLGazl.jpeg', alt: 'Interior Photography in Bahrain of Platinumlist Office in Seef showing the open area desks.' },
  { url: 'https://i.imgur.com/ynVPWSr.jpeg', alt: 'Interior Photograper in Bahrain of Platinumlist Office in Seef.' },
  { url: 'https://i.imgur.com/4WoZgqI.jpeg', alt: 'Interior Photography in Bahrain of Platinumlist Office in Seef.' },

];

const sohoHomesUrl = [
  { url: 'https://i.imgur.com/7bzelNw.jpeg', alt: 'Interior Photography in Bahrain of Soho Homes by Erwin Ereno Design in Adliya.' },
  { url: 'https://i.imgur.com/LCIrECK.jpeg', alt: 'Interior Photograper in Bahrain of Soho Homes by Erwin Ereno Design in Adliya.' },
  { url: 'https://i.imgur.com/LkN95jR.jpeg', alt: 'Interior Photographer in Bahrain of Soho Homes by Erwin Ereno Design in Adliya.' },
  { url: 'https://i.imgur.com/m87xyFG.jpeg', alt: 'Interior Photography in Bahrain of Soho Homes by Erwin Ereno Design in Adliya.' },

];

const voxCinemaUrl = [
  { url: 'https://i.imgur.com/VSPwUeA.jpeg', alt: 'Interior Fitout Photographer in Bahrain for VOX Cinema in City Centre Bahrain showcasing the facade.' },
  { url: 'https://i.imgur.com/ywHnwjr.jpeg', alt: 'Interior Photographer in Bahrain for VOX Cinema in City Centre Bahrain showing the main lobby and the food counter.' },
  { url: 'https://i.imgur.com/0uGj7cp.jpeg', alt: 'Interior Fitout Photography in Bahrain for VOX Cinema in City Centre Bahrain showing wooden design.' },
  { url: 'https://i.imgur.com/LCuIqN6.jpeg', alt: 'Fitout Photographer in Bahrain for VOX Cinema in City Centre Bahrain showing the IMAX facade.' },
  { url: 'https://i.imgur.com/4OGAhXL.jpeg', alt: 'Interior Photography in Bahrain for VOX Cinema in City Centre Bahrain showing the interior of IMAX theatre.' },
  { url: 'https://i.imgur.com/LRX38Rw.jpeg', alt: 'Interior Fitout Photography in Bahrain for VOX Cinema in City Centre Bahrain showing the carpet texture of the stars inside the auditorium.' },
  { url: 'https://i.imgur.com/n22HRac.jpeg', alt: 'Interior Photography in Bahrain for VOX Cinema in City Centre Bahrain showing the overall design of auditorium.' },
  { url: 'https://i.imgur.com/cL9eN2t.jpeg', alt: 'Fitout Photographer in Bahrain for VOX Cinema in City Centre Bahrain showing the facade of VIP Lounge called ThEATer.' },
  { url: 'https://i.imgur.com/yOfDv0v.jpeg', alt: 'Interior Photography in Bahrain for VOX Cinema in City Centre Bahrain showcasing the VIP Lounge in VOX Cinema.' },
  { url: 'https://i.imgur.com/jJl1Gp2.jpeg', alt: 'Interior Photography in Bahrain for VOX Cinema in City Centre Bahrain showcasing one of the four theatres of the VIP Lounge in VOX Cinema.' },
  
];

const etroTeaUrl = [
  { url: 'https://i.imgur.com/gecyna1.jpeg', alt: '' },
  { url: 'https://i.imgur.com/N4aL3xE.jpeg', alt: '' },
  { url: 'https://i.imgur.com/dOV1W5l.jpeg', alt: '' },
  { url: 'https://i.imgur.com/IozRIsE.jpeg', alt: '' },
  { url: 'https://i.imgur.com/tMjcQs1.jpeg', alt: '' },
  { url: 'https://i.imgur.com/1SkTYLW.jpeg', alt: '' },
  { url: 'https://i.imgur.com/xZruXEL.jpeg', alt: '' },
  { url: 'https://i.imgur.com/Hb4E3w9.jpeg', alt: '' },
  { url: 'https://i.imgur.com/puyHCDL.jpeg', alt: '' },
  { url: 'https://i.imgur.com/UVh3zdp.jpeg', alt: '' },
  { url: 'https://i.imgur.com/Aqk0qu3.jpeg', alt: '' },
  { url: 'https://i.imgur.com/sItkqvX.jpeg', alt: '' },
  { url: 'https://i.imgur.com/ROfzM1w.jpeg', alt: '' },
  { url: 'https://i.imgur.com/aH4ACW6.jpeg', alt: '' },
  { url: 'https://i.imgur.com/sIOh8kd.jpeg', alt: '' },
  
];




module.exports = { sohoHomesUrl, platinumlistOfficeUrl, retailUrl, homeUrl, bccUrl, realEstateUrl, uobPortfolioUrl, voxCinemaUrl, sanadVillaUrl, marassiBoulevardUrl, etroTeaUrl, busaiteenUrl, alHiddApartmentUrl, onyxApartmentUrl}
