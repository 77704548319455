import { bccUrl } from './ImageUrl.js'
import BccMasonry from './Masonry/BccMasonry.js'

export default function Bcc() {
  return (
    <div>
      <BccMasonry bccUrl={bccUrl} columnCount="2" gap="5"></BccMasonry>
    </div>
  )
}
