import { retailUrl } from './ImageUrl.js'
import RetailMasonry from './Masonry/RetailMasonry.js'

export default function Retail() {
  return (
    <div>
      <RetailMasonry retailUrl={retailUrl} columnCount="1" gap="5"></RetailMasonry>
    </div>
  )
}
