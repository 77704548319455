import { bcc23Url } from './ImageUrl.js'
import Bcc23Masonry from './Masonry/Bcc23Masonry.js'

export default function Bcc23() {
  return (
    <div>
      <Bcc23Masonry bcc23Url={bcc23Url} columnCount="2" gap="5"></Bcc23Masonry>
    </div>
  )
}
