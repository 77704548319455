import { platinumlistOfficeUrl } from './ImageUrl.js'
import PlatinumlistOfficeMasonry from './Masonry/PlatinumlistOfficeMasonry.js'

export default function PlatinumlistOffice() {
  return (
    <div>
      <PlatinumlistOfficeMasonry platinumlistOfficeUrl={platinumlistOfficeUrl} columnCount="1" gap="5"></PlatinumlistOfficeMasonry>
    </div>
  )
}
