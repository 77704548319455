import { etroTeaUrl } from './ImageUrl.js'
import EtroTeaMasonry from './Masonry/EtroTeaMasonry.js'

export default function EtroTea() {
  return (
    <div>
      <EtroTeaMasonry etroTeaUrl={etroTeaUrl} columnCount="2" gap="5"></EtroTeaMasonry>
    </div>
  )
}
