import { alHiddApartmentUrl } from './ImageUrl.js'
import AlHiddApartmentMasonry from './Masonry/AlHiddApartmentMasonry.js'

export default function AlHiddApartment() {
  return (
    <div>
      <AlHiddApartmentMasonry alHiddApartmentUrl={alHiddApartmentUrl} columnCount="1" gap="5"></AlHiddApartmentMasonry>
    </div>
  )
}
