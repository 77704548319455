import { sanadVillaUrl } from './ImageUrl.js'
import SanadVillaMasonry from './Masonry/SanadVillaMasonry.js'

export default function SanadVilla() {
  return (
    <div>
      <SanadVillaMasonry sanadVillaUrl={sanadVillaUrl} columnCount="1" gap="5"></SanadVillaMasonry>
    </div>
  )
}
