import { realEstateUrl } from './ImageUrl.js'
import RealEstateMasonry from './Masonry/RealEstateMasonry.js'

export default function RealEstate() {
  return (
    <div>
      <RealEstateMasonry realEstateUrl={realEstateUrl} columnCount="1" gap="5"></RealEstateMasonry>
    </div>
  )
}
