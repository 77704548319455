import { Link, useMatch, useResolvedPath } from "react-router-dom"
import instagram from "./instagramSvg.svg"

export default function Navbar() {
   
    return (
        <div class="navbar">
            <div class="title">
                <Link to="/"><b><h2>hassen arante</h2></b></Link>
            </div>

            <h1 class="h1-text">
                Bahrain Interior Photographer
            </h1>
    
              
            <div class="small-text">  
                <div class="margin-aboutme"> Interior Photography</div>
                <ul>
                    <div class="indentme">
                        <CustomLink to="/alhidd-apartment"><h3>Al Hidd Apartment</h3></CustomLink>
                        <CustomLink to="/busaiteen-kids-room"><h3>Busaiteen Kids' Rooms</h3></CustomLink>
                        <CustomLink to="/marassi-boulevard"><h3>Marassi Boulevard Apartment</h3></CustomLink>
                        <CustomLink to="/onyx-residences-apartment"><h3>Onyx Residences Apartment</h3></CustomLink>
                        <CustomLink to="/platinumlist-office"><h3>Platinumlist Office</h3></CustomLink>
                        <CustomLink to="/sanad-villa"><h3>Sanad Villa Room</h3></CustomLink>
                        <CustomLink to="/soho-homes"><h3>Soho Homes</h3></CustomLink>
                        <CustomLink to="/vox-cinema-city-centre-bahrain"><h3>VOX Cinema City Centre Bahrain</h3></CustomLink>
                        
                    </div>
                </ul>
                <br></br>
                <CustomLink to="/real-estate-portfolio"><h3>Real Estate Photography</h3></CustomLink>
                <div class="margin-aboutme">Events</div>
                    <ul>
                        <div class="indentme">
                            <CustomLink to="/bahrain-comic-con-2022"><h3>Bahrain Comic Con</h3></CustomLink>
                            <CustomLink to="/uob-portfolio"><h3>University of Bahrain</h3></CustomLink>
                        </div>

                        <div class="margin-aboutme">
                            <CustomLink to="/me">About Me</CustomLink>
                            <CustomLink to="/contact">Contact Me</CustomLink>
                        </div>
                    </ul>
                <div class="ig-flex">
                    <a href="https://www.instagram.com/hassenarante/" target="_blank">
                        <img height={20} src={instagram}></img>
                    </a>
                </div>
            </div>
        </div>
    )   
}

function CustomLink ({to, children, ...props}){
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}