import { sohoHomesUrl } from './ImageUrl.js'
import SohoHomesMasonry from './Masonry/SohoHomesMasonry.js'

export default function SohoHomes() {
  return (
    <div>
      <SohoHomesMasonry sohoHomesUrl={sohoHomesUrl} columnCount="1" gap="5"></SohoHomesMasonry>
    </div>
  )
}
