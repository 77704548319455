import { voxCinemaUrl } from './ImageUrl.js'
import VoxCinemaMasonry from './Masonry/VoxCinemaMasonry.js'

export default function VoxCinema() {
  return (
    <div>
      <VoxCinemaMasonry voxCinemaUrl={voxCinemaUrl} columnCount="1" gap="5"></VoxCinemaMasonry>
    </div>
  )
}
