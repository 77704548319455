import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";


const Contact = () => {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID,
      )
      .then(
        (result) => {
          setIsSent(true);
          form.current.reset();
          setTimeout(() => setIsSent(false), 5000); 
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <StyledContactForm>
      
      <h1 className="paragraph-header">
        Get in touch.
      </h1>
      
      <div>
        <div className="form-container">
          {isSent ? (
            <form ref={form} onSubmit={sendEmail}>
            <div className="nameemail">
              <div className="nameinput">
                <label>Name</label>
                <input type="text" name="from_name" required />
              </div>
              <div>
                <label>Email</label>
                <input type="email" name="from_email" required />
              </div>
            </div>
            <label>Message</label>
            <textarea name="message" rows="10" minlength="10" required />
            <input type="submit" value="Thank you!" />
          </form>
          ) : (
            <form ref={form} onSubmit={sendEmail}>
              <div className="nameemail">
                <div className="nameinput">
                  <label>Name</label>
                  <input type="text" name="from_name" required />
                </div>
                <div>
                  <label>Email</label>
                  <input type="email" name="from_email" required />
                </div>
              </div>
              <label>Message</label>
              <textarea name="message" rows="10" minlength="10" required />
              <input type="submit" value="Send" />
            </form>
          )}
        </div>   
      </div>
      
    </StyledContactForm>
  );
  
  
};

export default Contact;

const StyledContactForm = styled.div`
  
  margin-top: 3rem;
  

  form {
    font-family: 'Roboto', sans-serif;
    flex-direction: column;
    font-size: 14px;
    display: flex;
    min-width: 20%;
    max-width: 80%;

    .nameinput {
      margin-right: 1rem;
    }
    

    .nameemail {
      display: flex;
      flex-direction: row;
      
      
    }

    .form-container {
      
      
    }

    .container {
      display: flex;
      font-size: 14px;
    }

    .paragraph-header{
      text-indent: 0rem;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: var(--fs-xl);
      
    }

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border: 1px solid rgb(220, 220, 220);
      

      &:focus {
        border: 2px solid rgba(0, 0, 0, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      font-family: 'Roboto', sans-serif;
      padding: 7px;
      outline: none;
      border: 1px solid rgb(220, 220, 220);
      resize: vertical;

      &:focus {
        border: 2px solid rgba(0, 0, 0, 1);
      }
    }

    label {
      margin-top: 1rem;
      
    }

    input[type="submit"] {
      font-family: 'Roboto', sans-serif;
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(0, 0, 0);
      color: white;
      border: none;

      &:hover {
        background:rgb(15, 15, 15);
      }
    }



    
  }
`;
