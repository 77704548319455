import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import { handleRightClick } from './AppUtility'
import NavBar from './Pages/NavBar'
import Bcc from './Pages/Bcc'
import Me from './Pages/Me'
import Contact from './Pages/Contact'
import Bcc23 from './Pages/Bcc23'
import Home from './Pages/Home'
import RealEstate from './Pages/RealEstate'
import UobPortfolio from './Pages/UobPortfolio'
import Retail from './Pages/Retail'
import VoxCinema from './Pages/VoxCinema'
import MarassiBoulevard from './Pages/MarassiBoulevard'
import SanadVilla from './Pages/SanadVilla'
import EtroTea from './Pages/EtroTea'
import BusaiteenKidsRoom from './Pages/BusaiteenKidsRoom'
import AlHiddApartment from './Pages/AlHiddApartment'
import OnyxApartment from './Pages/OnyxApartment'
import PlatinumlistOffice from './Pages/PlatinumlistOffice'
import SohoHomes from './Pages/SohoHomes'

export default function App() {
  document.addEventListener('contextmenu', handleRightClick);

  return (
      <div className="container">
        <div class="flexbox-1">
          <NavBar />
        </div>
        <div class="flexbox-2">
            <Routes>
              <Route path="/" element={<Home />} />
              {/*<Route index element={<Home />} />*/}
              <Route path="/vox-cinema-city-centre-bahrain" element={<VoxCinema/>} />
              <Route path="/marassi-boulevard" element={<MarassiBoulevard />} />
              <Route path="/sanad-villa" element={<SanadVilla />} />
              <Route path="/busaiteen-kids-room" element={<BusaiteenKidsRoom />} />
              <Route path="/alhidd-apartment" element={<AlHiddApartment />} />
              <Route path="/onyx-residences-apartment" element={<OnyxApartment />} />
              <Route path="/platinumlist-office" element={<PlatinumlistOffice />} />
              <Route path="/soho-homes" element={<SohoHomes />} />


              <Route path="/real-estate-portfolio" element={<RealEstate />} />
              <Route path="/sitra-apartment" element={<Retail />} />
              <Route path="/etro-tea" element={<EtroTea />} />
              <Route path="/bahrain-comic-con-2022" element={<Bcc />} />
              <Route path="/bahrain-comic-con-2023" element={<Bcc23 />} />
              <Route path="/uob-portfolio" element={<UobPortfolio />} />
              <Route path="/me" element={<Me />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Home />} />
            </Routes>
        </div>
      </div>
  )
}