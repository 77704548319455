import { marassiBoulevardUrl } from './ImageUrl.js'
import MarassiBoulevardMasonry from './Masonry/MarassiBoulevardMasonry.js'

export default function MarassiBoulevard() {
  return (
    <div>
      <MarassiBoulevardMasonry marassiBoulevardUrl={marassiBoulevardUrl} columnCount="1" gap="5"></MarassiBoulevardMasonry>
    </div>
  )
}
